.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  width: 70%;
  height: fit-content;
  min-height: 200px;
  padding: 20px 25px;
  margin-top: 20px;

  background-color: #272c34;
  border-radius: 6px;
}

.upload-form-empty {
  justify-content: center;
}

.file-list {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding: 10px 0;
  margin: 10px 0;
}

.file-list-empty {
  border: 0px;
}

.title {
  margin: 10px 0;
  color: white;
}

.file-row {
  display: flex;
  justify-content: space-between;

  padding: 12px;
}

.file-name {
  color: rgba(255, 255, 255, 0.7);
}

.delete-btn {
  display: inline-block;
  font-size: 14px;
  width: 60;
  height: 30;
  color: red;
  background-color: transparent;
  text-transform: uppercase;
  outline: none;
  text-decoration: none;
  border: none;
  padding: 4px 8px;
  text-align: center;
}

.delete-btn:hover {
  color: #d80a0a;
  cursor: pointer;
}

.submit-btn {
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  outline: none;
  text-decoration: none;
  border: none;
  text-align: center;

  color: #272c34;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 12px 24px;
  margin-top: 20px;
  border-radius: 8px;
}

.submit-btn:hover:not(:disabled) {
  opacity: 0.9;
  cursor: pointer;
}

.submit-btn:disabled {
  background-color: #a6a1a1;
}

.error {
  color: red;
  margin: 10px 0;
}

.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.input {
  width: 80%;
  padding: 8px 10px;
  border: none;
  outline: none;
  background: #494a4c;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;

}
